import { CategoryService } from '@vitafy/storefront-api-contracts-fetch';
import dynamic from 'next/dynamic';

import { getLayoutData } from '@backend/layoutData';
import { isStoreCode } from '@common/typeCheckers';
import { makeUrlAbsolute } from '@common/utlis';
import { reduceProductHit } from '@stories/organisms/HitListView/HitCompact/reducers';
import { getSearchClient, getSfApiClient, setCacheHeaderForSSR, setupRequests } from '@utils/helpers';
import { emptyLandingpageData, getLandingpageData } from '@utils/hygraph';
import { getChildRequestLogger } from '@utils/logger';

import Layout from '../../../components/layout';

import type { LayoutProps } from '../../../components/layout';
import type { NextPageWithLayout } from '../../_app';
import type {
  CategoryListFragment,
  CompactProductsCarouselFragment,
  GetLandingpageDataQuery
} from '@gql/hygraph/graphql';
import type { IArticleProps } from '@stories/molecules/ArticleCard';
import type { CategoryListItemFromDBDto } from '@vitafy/storefront-api-contracts-fetch';
import type { GetServerSideProps } from 'next';

setupRequests();

type TPropsReducerParams<T extends object> = {
  id: string;
  storeCode: string;
} & T;

export const modulesSettings = {
  adCarousel: {
    dynamicModule: dynamic(() => import('@stories/organisms/AdCarousel/AdCarousel'))
  },
  BuyerGuide: {
    dynamicModule: dynamic(() => import('@stories/organisms/BuyerGuides/BuyerGuide')),
    propsReducer: async (props: { categoryId: number; storeId: number }): Promise<IArticleProps[] | []> => {
      const { categoryId, storeId } = props;
      const sfApiClient = await getSfApiClient();
      const res = await sfApiClient.wpContent.getPostsByCategory({
        params: { storeCodeOrId: storeId, categoryId: `${categoryId}` }
      });
      if (res.status !== 200) {
        // TODO: error must be logged
        return [];
      }
      const articles = res.body;
      return articles.map((responseArticle) => {
        const { ID, title, content, postImageUrl, post_type, recipeCookingTime, recipeDifficulty, url } =
          responseArticle;
        const tags = [
          ...(recipeCookingTime ? [{ type: 'time', label: recipeCookingTime }] : []),
          ...(recipeDifficulty ? [{ type: 'easy', label: recipeDifficulty }] : [])
        ];

        return {
          id: ID,
          type: post_type,
          tags,
          name: title,
          imageSrc: postImageUrl,
          text: content,
          to: url
        };
      });
    }
  },
  CampaignCarousel: {
    dynamicModule: dynamic(() => import('@stories/organisms/CampaignCarousel/CampaignCarousel'))
  },
  CategoryListSection: {
    dynamicModule: dynamic(() => import('@stories/organisms/CategoryList/CategoryListSection')),
    propsReducer: async ({ storeCode, hideCategoryIDs }: TPropsReducerParams<CategoryListFragment>) => {
      const response = await CategoryService.categoryControllerGetCategoriesFromDb({
        storeCodeOrId: storeCode,
        menu: true,
        addCmsBlocks: false
      });

      const removeNodesById = (
        tree: { [key: string]: unknown; children: CategoryListItemFromDBDto[] },
        idsToRemove: number[] = []
      ) => ({
        ...tree,
        children: tree.children
          .filter((node) => !idsToRemove.includes(node.id))
          .map((child) => removeNodesById(child, idsToRemove))
      });

      return removeNodesById(
        {
          id: 1,
          name: 'root',
          url: '',
          path: [1],
          type: 'category',
          thumbnail: '',
          children: response
        },
        hideCategoryIDs
      );
    }
  },
  CompactProductsCarousel: {
    dynamicModule: dynamic(
      () => import('@stories/organisms/Carousel/CompactProductsCarousel/CompactProductsCarousel')
    ),
    propsReducer: async ({
      categories,
      headline,
      storeCode
    }: TPropsReducerParams<CompactProductsCarouselFragment>) => {
      const searchClient = await getSearchClient();
      const categoriesWithProducts = await Promise.all(
        categories.map(async ({ categoryId, categoryName }) => {
          const response = await searchClient.searchProductsByCategory({
            query: {
              storeCode: storeCode,
              categoryId,
              resultsPerPage: 12
            }
          });

          const categoryData = await CategoryService.categoryControllerGetCategoryById({
            storeCodeOrId: storeCode,
            categoryId
          });

          if (response.status !== 200) {
            return;
          }

          return {
            products: response.body.initialStoreState.hits.slice(0, 12).map(reduceProductHit),
            label: categoryName,
            href: makeUrlAbsolute(categoryData?.url)
          };
        })
      );

      const resolvedPromises = await Promise.all(categoriesWithProducts);
      return {
        headline,
        productCategories: resolvedPromises
      };
    }
  },
  HeroCinematic: {
    dynamicModule: dynamic(() => import('@stories/organisms/HeroCinematic/HeroCinematic'))
  },
  InfluencerReviewCarousel: {
    dynamicModule: dynamic(
      () => import('@stories/organisms/InfluencerReviewCarousel/InfluencerReviewCarousel')
    )
  },
  LinkStripe: {
    dynamicModule: dynamic(() => import('@stories/organisms/LinkStripe/LinkStripe'))
  },
  Quicklinks: {
    dynamicModule: dynamic(() => import('@stories/organisms/QuicklinksWithIcon/QuicklinksWithIcon'))
  },
  TrustedShopReviewsCarousel: {
    dynamicModule: dynamic(() => import('@stories/organisms/ReviewsSocialProof/ReviewWithBackground'))
  },
  UserReviewsCarousel: {
    dynamicModule: dynamic(() => import('@stories/organisms/UserReviewsCarousel/UserReviewsCarousel'))
  },
  UspCardPack: { dynamicModule: dynamic(() => import('@stories/organisms/USPCardPack/USPCardPack')) }
};

type TLandingpageModule = NonNullable<GetLandingpageDataQuery['composablePage']>['modules'][number];

async function getComponentProps(landingpageModule: TLandingpageModule, storeCode: string) {
  const commonProps = {
    storeCode,
    id: landingpageModule.id
  };
  switch (landingpageModule.__typename) {
    case 'AdCarousel': {
      return {
        associatedReactComponentName: 'adCarousel' as const,
        props: {
          ...commonProps,
          ...landingpageModule,
          ratio: { mobile: 1, tablet: 2.5, desktop: 4.25 }
        }
      };
    }
    case 'BuyerGuide': {
      const categoryId = landingpageModule.buyerGuideCategoryId;
      const storeId = {
        vomachterhof: 31
      }[storeCode]; // TODO

      if (!storeId) {
        throw new Error('Invalid storeCode');
      }

      const remoteProps = categoryId
        ? await modulesSettings.BuyerGuide.propsReducer({ categoryId, storeId })
        : {};

      return {
        associatedReactComponentName: 'BuyerGuide' as const,
        props: {
          ...landingpageModule,
          articles: remoteProps
        }
      };
    }
    case 'CampaignCarousel': {
      return {
        associatedReactComponentName: 'CampaignCarousel' as const,
        props: landingpageModule
      };
    }
    case 'CategoryList': {
      const remoteProps = await modulesSettings.CategoryListSection.propsReducer({
        ...commonProps,
        ...landingpageModule
      });
      return {
        associatedReactComponentName: 'CategoryListSection' as const,
        props: {
          ...remoteProps,
          ...landingpageModule,
          url: '/search'
        }
      };
    }
    case 'CompactProductsCarousel': {
      return {
        associatedReactComponentName: 'CompactProductsCarousel' as const,
        props: await modulesSettings.CompactProductsCarousel.propsReducer({
          ...commonProps,
          ...landingpageModule
        })
      };
    }
    case 'HeroCinematic': {
      return {
        associatedReactComponentName: 'HeroCinematic' as const,
        props: {
          ...commonProps,
          ...landingpageModule
        }
      };
    }
    case 'LinkStripe': {
      return {
        associatedReactComponentName: 'LinkStripe' as const,
        props: {
          ...landingpageModule
        }
      };
    }
    case 'TrustedShopReviewsCarousel': {
      return {
        associatedReactComponentName: 'TrustedShopReviewsCarousel' as const,
        props: {
          ...landingpageModule
        }
      };
    }
    case 'InfluencerReviewCarousel': {
      return {
        associatedReactComponentName: 'InfluencerReviewCarousel' as const,
        props: {
          ...landingpageModule
        }
      };
    }
    case 'Quicklinks': {
      return {
        associatedReactComponentName: 'Quicklinks' as const,
        props: {
          ...landingpageModule
        }
      };
    }
    case 'UspCardPack': {
      return {
        associatedReactComponentName: 'UspCardPack' as const,
        props: {
          mobileBehavior: 'compact',
          ...landingpageModule
        }
      };
    }
    case 'UserReviewsCarousel': {
      return {
        associatedReactComponentName: 'UserReviewsCarousel' as const,
        props: {
          ...landingpageModule
        }
      };
    }
    default:
      return null;
  }
}

type TDynamicModule = Awaited<ReturnType<typeof getComponentProps>>;

type THomepageParams = { store: string };

type THomepageProps = {
  dynamicModules: Array<TDynamicModule>;
} & LayoutProps;

const hygraphQueryErrorHandler = (error) => {
  console.error(error);
  return emptyLandingpageData;
};

export const getServerSideProps: GetServerSideProps<THomepageProps, THomepageParams> = async (context) => {
  const { req, res, params, query, preview } = context;
  const log = getChildRequestLogger(req, params, query);
  const { store: storeCode } = params || {};

  if (!storeCode || !isStoreCode(storeCode)) {
    log.error('Invalid params: store');
    throw new Error('Invalid params: store');
  }

  // Cache this page only if this is not a preview
  if (!preview) {
    log.debug('Setting cache header for SSR');
    setCacheHeaderForSSR(res);
  }

  try {
    const [layoutProps, hygraphData] = await Promise.all([
      getLayoutData({
        context,
        storeCode,
        pagePath: '/',
        pageType: '',
        isDraftMode: preview
      }),
      getLandingpageData('home', preview).catch(hygraphQueryErrorHandler)
    ]);

    const dynamicModules = await Promise.all(
      hygraphData.modules.map((landingpageModule) => getComponentProps(landingpageModule, storeCode))
    );

    return {
      props: {
        ...layoutProps,
        dynamicModules
      }
    };
  } catch (error) {
    log.error(error, 'Rendering homepage failed');
    // Render 500 page
    throw error;
  }
};

const Homepage: NextPageWithLayout<THomepageProps> = ({ dynamicModules }) => {
  return (
    <>
      {dynamicModules
        .filter((module) => module !== null)
        .map(({ associatedReactComponentName, props }, index) => {
          const ReactComponent = modulesSettings[associatedReactComponentName].dynamicModule;
          return <ReactComponent key={index} {...props} />;
        })}
    </>
  );
};

Homepage.getLayout = function getLayout(page, pageProps) {
  return (
    <Layout {...pageProps} cinematicMode={true}>
      {page}
    </Layout>
  );
};

export default Homepage;
