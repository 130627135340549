
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[store]/magazine/homepage",
      function () {
        return require("private-next-pages/[store]/magazine/homepage.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[store]/magazine/homepage"])
      });
    }
  